import React, { PureComponent } from 'react'

import UnderConstruction from '@/assets/under-construction.svg'

export default class E423 extends PureComponent {
	render() {
		return (
			<div className = 'column align-items-center'>
				<img
					src = {UnderConstruction}
					alt = 'Under construction'
					className = 'error-image'
				/>
				<h1>We're still working on this page!</h1>
			</div>
		)
	}
}