import React, { PureComponent } from 'react'
import marked from 'marked'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'

export default class CreatePost extends PureComponent {
  state = {
    error: '',
    title: '',
    author: '',
    slug: '',
    featureImage: '',
    post: '',
    authors: [],
    status: 'In progress',
    loading: true,
    showModal: false,
  }
  componentDidMount = async () => {
    await this.fetchAuthors()
    this.setState({
      loading: false
    })
  }
  fetchAuthors = async (ev) => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { admins, auth, error } = await res.json()
    if(auth) {
      this.setState({
        authors: admins
      })
    } else {
      this.setState({
        error
      })
    }
  }
  createPost = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/create/post`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        title: this.state.title,
        authorId: this.state.author.id,
        slug: this.state.slug,
        featureImage: this.state.featureImage,
        post: this.state.post,
        status: this.state.status,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        showModal: true
      })
    } else {
      this.setState({
        error
      })
    }
  }
  closeModal = () => {
    this.props.history.push('/posts')
  }
  createMarkup = (content) => {
    if(content) {
      const html = marked(content)
      return { __html: html }
    } else {
      return { __html: ' '}
    }
  }
  setAuthor = async (id) => {
    let author = await this.state.authors.filter(author => author.id === id)
    this.setState({
      author: author[0]
    })
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Successfully uploaded!`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.createPost}>
          <select onChange = {(e) => this.setState({ status: e.target.value })} className = {this.state.status === 'In progress' ? 'block fixed in-progress' : this.state.status === 'inactive' ? 'block fixed inactive' : this.state.status === 'active' ? 'block fixed' : 'block fixed'}>
            <option value = 'In progress'>In progress</option>
            <option value = 'inactive'>inactive</option>
            <option value = 'active'>active</option>
          </select>
          <input
            placeholder = 'Title'
            onChange = {(e) => this.setState({title: e.target.value})}
            className = 'block fixed'
            value = {this.state.title}
          />
          <select value = {this.state.author.id} onChange = {(e) => this.setAuthor(e.target.value)} className = 'block fixed'>
            <option value = 'null'>Select an author</option>
            {
              this.state.authors.map(author => {
                return (
                  <option value = {author.id}>{author.user.firstName} {author.user.lastName}</option>
                )
              })
            }
          </select>
          <input
            placeholder = 'Slug'
            onChange = {(e) => this.setState({slug: e.target.value})}
            className = 'block fixed'
            value = {this.state.slug}
          />
          <input
            placeholder = 'Feature Image URL'
            onChange = {(e) => this.setState({featureImage: e.target.value})}
            className = 'block fixed'
            value = {this.state.featureImage}
          />
          <textarea
            placeholder = 'Post'
            onChange = {(e) => this.setState({post: e.target.value})}
            className = 'block fixed'
            value = {this.state.post}
            rows = '5'
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Create post</button>
        </form>
        <div className = 'flex-3 margin'>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Status: {this.state.status}</p>
            <p className = 'small thin'>Title: {this.state.title}</p>
            <p className = 'small thin'>Author: {this.state.author ? `${this.state.author.user.firstName} ${this.state.author.user.lastName}` : null}</p>
            <p className = 'small thin'>Slug: {this.state.slug}</p>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Feature Image</p>
            <img
              src = {this.state.featureImage}
              alt = 'feature'
            />
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Post</p>
            <div className = 'preview' dangerouslySetInnerHTML = {this.createMarkup(this.state.post)} />
          </div>
        </div>
      </div>
    )
  }
}