import React, { PureComponent } from 'react'
import moment from 'moment'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'
import RedemptionCard from '@/components/RedemptionCard'

export default class Coupon extends PureComponent {
  state = {
    error: '',
    affiliate: null,
    defaulted: false,
    name: '',
    code: '',
    domain: '',
    duration: '',
    durationInMonths: '',
    valid: false,
    redemptions: [],
    showModal: false,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchCoupon()
    await this.fetchRedemptions()
    this.setState({
      loading: false,
    })
  }
  fetchCoupon = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/coupons/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { coupon, auth, error } = await res.json()
    if(auth) {
      this.setState({
        id: coupon.id,
        affiliate: coupon.affiliate,
        name: coupon.name,
        code: coupon.code,
        valid: coupon.valid,
        defaulted: coupon.default,
        subscriptionType: coupon.subscriptionType,
        expiry: coupon.expiry,
        limit: coupon.limit,
        amountOff: coupon.amountOffInCents/100,
        percentOff: coupon.percentOff,
        domain: coupon.domain,
        duration: coupon.duration,
        durationInMonths: coupon.durationInMonths,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchRedemptions = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/coupons/${id}/redemptions`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { redemptions, auth, error } = await res.json()
    if(auth) {
      this.setState({
        redemptions
      })
    } else {
      this.setState({
        error
      })
    }
  }
  updateCoupon = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/update/coupon`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        id: this.state.id,
        name: this.state.name,
        defaulted: this.state.defaulted,
        valid: this.state.valid,
        domain: this.state.domain,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        error,
        showModal: true,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  setValidity = (validity) => {
    const valid = validity === 'false' ? false : true
    this.setState({
      valid
    })
  }
  setDefault = (defaulted) => {
    const d = defaulted === 'false' ? false : true
    this.setState({
      defaulted: d
    })
  }
  closeModal = () => {
    this.setState({
      showModal: false
    })
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Coupon updated.`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.updateCoupon}>
          <select value = {this.state.valid} onChange = {(e) => this.setValidity(e.target.value)} className = {this.state.valid ? 'block fixed' : 'block fixed inactive'}>
            <option value = {false}>Invalid</option>
            <option value = {true}>Valid</option>
          </select>
          <select value = {this.state.defaulted} onChange = {(e) => this.setDefault(e.target.value)} className = {this.state.defaulted ? 'block fixed' : 'block fixed inactive'}>
            <option value = {false}>Not default</option>
            <option value = {true}>Default</option>
          </select>
          <input
            placeholder = 'Name'
            onChange = {(e) => this.setState({ name: e.target.value })}
            className = 'block fixed'
            value = {this.state.name}
          />
          <input
            placeholder = 'Domain'
            onChange = {(e) => this.setState({ domain: e.target.value })}
            className = 'block fixed'
            value = {this.state.domain}
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Save</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Affiliate: {this.state.affiliate.name}</p>
            <p className = 'small thin'>Valid: {this.state.valid ? 'Valid' : 'Invalid'}</p>
            <p className = 'small thin'>Defaulted: {this.state.defaulted ? 'Default' : 'Not Default'}</p>
            <p className = 'small thin'>Name: {this.state.name}</p>
            <p className = 'small thin'>Code: {this.state.code}</p>
            <p className = 'small thin'>Expiry: {this.state.expiry ? moment.unix(this.state.expiry).format('LLLL') : 'No expiry'}</p>
            <p className = 'small thin'>Limit: {this.state.limit ? this.state.limit : 'No limit'}</p>
            <p className = 'small thin'>Domain: {this.state.domain ? this.state.domain : 'No domain'}</p>
            <p className = 'small thin'>Duration: {this.state.duration}</p>
            <p className = 'small thin'>Duration in months: {this.state.durationInMonths}</p>
            <p className = 'small thin'>
              Offer: {this.state.percentOff ? `${this.state.percentOff}% off ` : `$${this.state.amountOff}USD off `}
              {this.state.subscriptionType === 'monthly' ? 'for monthly only.' : this.state.subscriptionType === 'yearly' ? 'for yearly only.' : 'for both monthly and yearly.'}
            </p>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Redemptions</p>
            <div className = 'column'>
              <div className = 'row'>
                <button className = 'block round in-progress fixed'>Premium</button>
                <button className = 'block round fixed'>Free</button>
                <button className = 'block round sample fixed'>Delinquent</button>
                <button className = 'block round inactive fixed'>Churned</button>
              </div>
              {
                this.state.redemptions.map(redemption => {
                  return <RedemptionCard redemption = {redemption} />
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}