import React, { PureComponent } from 'react'
import moment from 'moment'

export default class UserRedemptionsCard extends PureComponent {
  render() {
    return (
      <button className = 'block text-align-left sample'>
        <div className = 'row justify-content-space-between'>
          <p className = 'no-margin'>{this.props.redemption.coupon.name} ({this.props.redemption.coupon.code})</p>
          <p className = 'no-margin'>{moment(this.props.redemption.coupon.createdAt).format('LLLL')}</p>
        </div>
      </button>
    )
  }
}