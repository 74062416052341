import React, { PureComponent } from 'react'

import Spinner from '@/components/Spinner'

export default class Login extends PureComponent {
	state = {
		loading: null,
		error: null,
	}
	login = async (ev) => {
		ev.preventDefault()
    this.setState({
      error: null,
      loading: true,
    })
    const res = await fetch (`/api/admins/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      })
    })
    const { auth, token, error } = await res.json()
    if(auth) {
    	await this.props.handleLogin(token)
    }
    this.setState({
      error,
      loading: false
    })
	}
	render() {
		if(this.state.loading) {
			return <div className = 'login-form'><Spinner /></div>
		}
		return (
			<div className = 'login-form'>
				<form onSubmit = {this.login} className = 'card fixed block'>
					<div>
						<label className = 'formLabel'>Email</label>
            <input
              onChange = {(e) => this.setState({ email : e.target.value })}
              type = 'email'
              placeholder = 'Email'
              value = {this.state.email}
              className = 'block'
            />
					</div>
					<div>
						<label className = 'formLabel'>Password</label>
            <input
              onChange = {(e) => this.setState({ password : e.target.value })}
              type = 'password'
              placeholder = 'Password'
              value = {this.state.password}
              className = 'block'
            />
					</div>
          <span>{this.state.error}</span>
          <button className = 'accent block'>Login</button>
				</form>
			</div>
		)
	}
}