import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

export default class ProblemCard extends PureComponent {
  render() {
    if(this.props.problem.status === 'inactive') {
      return (
        <button className = 'block text-align-left inactive'>
          <NavLink className = 'row justify-content-space-between' to = {`/problems/${this.props.problem.id}`}>
            <p className = 'no-margin'>{this.props.problem.title}</p>
            <p className = 'no-margin'>{this.props.problem.comments.length} comments</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.problem.status === 'Sample') {
      return (
        <button className = 'block text-align-left sample'>
          <NavLink className = 'row justify-content-space-between' to = {`/problems/${this.props.problem.id}`}>
            <p className = 'no-margin'>{this.props.problem.title}</p>
            <p className = 'no-margin'>{this.props.problem.comments.length} comments</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.problem.status === 'In progress') {
      return (
        <button className = 'block text-align-left in-progress'>
          <NavLink className = 'row justify-content-space-between' to = {`/problems/${this.props.problem.id}`}>
            <p className = 'no-margin'>{this.props.problem.title}</p>
            <p className = 'no-margin'>{this.props.problem.comments.length} comments</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.problem.status === 'active') {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/problems/${this.props.problem.id}`}>
            <p className = 'no-margin'>{this.props.problem.problemNumber}. {this.props.problem.title}</p>
            <p className = 'no-margin'>{this.props.problem.comments.length} comments</p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}