import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

export default class AffiliateCard extends PureComponent {
  render() {
    if(this.props.affiliate.ref === 'dpp') {
      return (
        <button className = 'block text-align-left sample'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/${this.props.affiliate.id}`}>
            <p className = 'no-margin'>{this.props.affiliate.name} ({this.props.affiliate.ref})</p>
            <p className = 'no-margin'>{this.props.affiliate.status} • {moment(this.props.affiliate.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.affiliate.status === 'inactive') {
      return (
        <button className = 'block text-align-left inactive'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/${this.props.affiliate.id}`}>
            <p className = 'no-margin'>{this.props.affiliate.name} ({this.props.affiliate.ref})</p>
            <p className = 'no-margin'>{this.props.affiliate.status} • {moment(this.props.affiliate.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.affiliate.status === 'active') {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/${this.props.affiliate.id}`}>
            <p className = 'no-margin'>{this.props.affiliate.name} ({this.props.affiliate.ref})</p>
            <p className = 'no-margin'>{this.props.affiliate.status} • {moment(this.props.affiliate.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}