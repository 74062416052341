import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import './index.css'

export default class Navigator extends PureComponent {
	render() {
		return (
			<div className = 'navigator'>
				<div className = 'column'>
					<p className = 'navigator-title'>ADMIN</p>
					<NavLink onClick = {() => this.props.setRouteName('Users')} className = 'path' to = '/users'>Users</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Problems')} className = 'path' to = '/problems'>Problems</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Comments')} className = 'path' to = '/comments'>Comments</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Posts')} className = 'path' to = '/posts'>Posts</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Companies')} className = 'path' to = '/companies'>Companies</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Affiliates')} className = 'path' to = '/affiliates'>Affiliates</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Promotions')} className = 'path' to = '/promotions'>Promotions</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Referrals')} className = 'path' to = '/referrals'>Referrals</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Feedback')} className = 'path' to = '/feedback'>Feedback</NavLink>
				</div>
				<div className = 'column'>
					<p className = 'navigator-title'>ANALYTICS</p>
					<NavLink onClick = {() => this.props.setRouteName('Overview')} className = 'path' to = '/analytics'>Overview</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Revenue')} className = 'path' to = '/revenue'>Revenue</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Growth')} className = 'path' to = '/growth'>Growth</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Engagement')} className = 'path' to = '/engagement'>Engagement</NavLink>
					<NavLink onClick = {() => this.props.setRouteName('Retention')} className = 'path' to = '/retention'>Retention</NavLink>
				</div>
				<div className = 'column'>
					<NavLink onClick = {() => this.props.setRouteName('Settings')} className = 'path' to = '/settings'>Settings</NavLink>
					<NavLink className = 'path' to = '/logout'>Logout</NavLink>
					<p className = 'path xsmall word-break-break-all'>Logged in as:&nbsp;<strong>{this.props.user.user.firstName} {this.props.user.user.lastName}</strong></p>
				</div>
			</div>
		)
	}
}