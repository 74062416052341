import React, { PureComponent } from 'react'
import moment from 'moment'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'

export default class Affiliation extends PureComponent {
  state = {
    error: '',
    id: null,
    user: null,
    affiliate: null,
    dealType: 'grant',
    status: 'active',
    ref: null,
    hasExpiry: false,
    expiry: null,
    month: '12',
    day: '31',
    year: '2040',
    hour: '12',
    minute: '00',
    clock: 'AM',
    showModal: false,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchAffiliation()
    this.setState({
      loading: false,
    })
  }
  fetchAffiliation = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/affiliateUsers/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { affiliateUser, auth, error } = await res.json()
    if(auth) {
      this.setState({
        id: affiliateUser.id,
        user: affiliateUser.user,
        affiliate: affiliateUser.affiliate,
        dealType: affiliateUser.dealType,
        status: affiliateUser.status,
        hasExpiry: affiliateUser.expiry ? true : false,
        expiry: affiliateUser.expiry,
        ref: affiliateUser.ref,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  updateAffiliation = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/update/affiliateUser`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        id: this.state.id,
        ref: this.state.ref,
        status: this.state.status,
        dealType: this.state.dealType,
        expiry: this.state.expiry,
        hasExpiry: this.state.hasExpiry,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        error,
        showModal: true,
      })
    } else {
      this.setState({
        error
      })
    }
  }

  setHasExpiry = (value) => {
    const hasExpiry = value === 'false' ? false : true
    this.setState({
      hasExpiry,
    })
  }
  setExpiry = async () => {
    const realHour = this.state.hour === 12 && this.state.clock === 'AM' ? '00' : this.state.clock === 'AM' ? this.state.hour : this.state.hour === 12 ? '12' : parseInt(this.state.hour)+12
    const dateToParse = `${this.state.year}-${this.state.month}-${this.state.day}T${realHour}:${this.state.minute}`
    const date = await moment(dateToParse).unix()
    this.setState({
      expiry: date
    })
  }
  setDatetime = async (section, value) => {
    if(section === 'month') {
      await this.setState({
        month: value
      })
    } else if(section === 'day') {
      const fixed = value < 10 ? `0${value}` : value
      await this.setState({
        actualDay: value,
        day: fixed
      })
    } else if(section === 'year') {
      await this.setState({
        year: value
      })
    } else if(section === 'hour') {
      await this.setState({
        hour: value
      })
    } else if(section === 'minute') {
      await this.setState({
        minute: value
      })
    } else if(section === 'clock') {
      await this.setState({
        clock: value
      })
    }
    await this.setExpiry()
  }
  closeModal = () => {
    this.setState({
      showModal: false
    })
  }
  render() {
    const days = ['1','2','3','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
    const years = ['2020','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030','2031','2032','2033','2034','2035','2036','2037','2038','2039','2040']
    const hours = ['01','02','03','05','06','07','08','09','10','11','12']
    const minutes = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59','60']
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Affiliation updated.`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.updateAffiliation}>
          <input
            className = 'block fixed'
            value = {`User: ${this.state.user ? this.state.user.email : null}`}
          />
          <input
            className = 'block fixed'
            value = {`Affiliate: ${this.state.affiliate ? this.state.affiliate.name : null}`}
          />
          <select value = {this.state.status} onChange = {(e) => this.setState({ status: e.target.value })} className = {this.state.status === 'inactive' ? 'block fixed inactive' : this.state.status === 'active' ? 'block fixed' : 'block fixed'}>
            <option value = 'inactive'>inactive</option>
            <option value = 'active'>active</option>
          </select>
          <select value = {this.state.dealType} onChange = {(e) => this.setState({ dealType: e.target.value })} className = {this.state.status === 'coupon' ? 'block fixed' : this.state.status === 'grant' ? 'block fixed in-progress' : 'block fixed'}>
            <option value = 'grant'>grant</option>
            <option value = 'coupon'>coupon</option>
          </select>
          <input
            placeholder = 'Ref'
            onChange = {(e) => this.setState({ref: e.target.value})}
            className = 'block fixed'
            value = {this.state.ref}
          />
          <select value = {this.state.hasExpiry} onChange = {(e) => this.setHasExpiry(e.target.value)} className = {this.state.hasExpiry ? 'block fixed' : 'block fixed inactive'}>
            <option value = {false}>No expiry</option>
            <option value = {true}>Has expiry</option>
          </select>
          {
            this.state.hasExpiry ? (
              <div className = 'column'>
                <div className = 'row'>
                  <select value = {this.state.month} onChange = {(e) => this.setDatetime('month', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    <option value = '01'>January</option>
                    <option value = '02'>February</option>
                    <option value = '03'>March</option>
                    <option value = '04'>April</option>
                    <option value = '05'>May</option>
                    <option value = '06'>June</option>
                    <option value = '07'>July</option>
                    <option value = '08'>August</option>
                    <option value = '09'>September</option>
                    <option value = '10'>October</option>
                    <option value = '11'>November</option>
                    <option value = '12'>December</option>
                  </select>
                  <select value = {this.state.actualDay} onChange = {(e) => this.setDatetime('day', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    {
                      days.map(day => {
                        return (
                          <option value = {day}>{day}</option>
                        )
                      })
                    }
                  </select>
                  <select value = {this.state.year} onChange = {(e) => this.setDatetime('year', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    {
                      years.map(year => {
                        return (
                          <option value = {year}>{year}</option>
                        )
                      })
                    }
                  </select>
                  <select value = {this.state.hour} onChange = {(e) => this.setDatetime('hour', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    {
                      hours.map(hour => {
                        return (
                          <option value = {hour}>{hour}</option>
                        )
                      })
                    }
                  </select>
                  <select value = {this.state.minute} onChange = {(e) => this.setDatetime('minute', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    {
                      minutes.map(minute => {
                        return (
                          <option value = {minute}>{minute}</option>
                        )
                      })
                    }
                  </select>
                  <select value = {this.state.clock} onChange = {(e) => this.setDatetime('clock', e.target.value)} className = 'fixed wrapper block'>
                    <option value = 'AM'>AM</option>
                    <option value = 'PM'>PM</option>
                  </select>
                </div>
              </div>
            ) : null
          }
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Save</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>User: {this.state.user ? this.state.user.email : null}</p>
            <p className = 'small thin'>Affiliate: {this.state.affiliate ? this.state.affiliate.name : null}</p>
            <p className = 'small thin'>Status: {this.state.status}</p>
            <p className = 'small thin'>Deal Type: {this.state.dealType}</p>
            <p className = 'small thin'>Ref: {this.state.ref}</p>
            <p className = 'small thin'>Expiry: {this.state.hasExpiry ? moment.unix(this.state.expiry).format('LLLL') : 'No expiry'}</p>
          </div>
        </div>
      </div>
    )
  }
}