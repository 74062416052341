import React, { PureComponent } from 'react'
import marked from 'marked'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'

export default class CreateProblem extends PureComponent {
  state = {
    error: '',
    status: 'In progress',
    difficulty: 'Easy',
    title: '',
    problem: '',
    topics: [],
    companies: [],
    selectedCompanies: [],
    showModal: false,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchCompanies()
    this.setState({
      loading: false,
    })
  }
  createProblem = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/create/problem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        status: this.state.status,
        title: this.state.title,
        difficulty: this.state.difficulty,
        problem: this.state.problem,
        topics: this.state.topics,
        companies: this.state.selectedCompanies,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        showModal: true
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchCompanies = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/companies`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { companies, auth, error } = await res.json()
    if(auth) {
      this.setState({
        companies,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  closeModal = () => {
    this.props.history.push('/problems')
  }
  setTopics = async (ev, topic) => {
    ev.preventDefault()
    let currentTopics = await this.state.topics
    this.setState({
      topics: []
    })
    if(currentTopics.indexOf(topic) >= 0) {
      await currentTopics.splice(currentTopics.indexOf(topic), 1)
    } else {
      await currentTopics.push(topic)
    }
    await this.setState({
      topics: currentTopics
    })
  }
  setCompanies = async (ev, company) => {
    ev.preventDefault()
    let currentCompanies = await this.state.selectedCompanies
    this.setState({
      selectedCompanies: []
    })
    if(currentCompanies.indexOf(company) >= 0) {
      await currentCompanies.splice(currentCompanies.indexOf(company), 1)
    } else {
      await currentCompanies.push(company)
    }
    await this.setState({
      selectedCompanies: currentCompanies
    })
  }
  createMarkup = (content) => {
    if(content) {
      const html = marked(content)
      return { __html: html }
    } else {
      return { __html: ' '}
    }
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Successfully uploaded!`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.createProblem}>
          <select onChange = {(e) => this.setState({ status: e.target.value })} className = {this.state.status === 'In progress' ? 'block fixed in-progress' : this.state.status === 'Sample' ? 'block fixed sample' : this.state.status === 'inactive' ? 'block fixed inactive' : this.state.status === 'active' ? 'block fixed' : 'block fixed'}>
            <option value = 'In progress'>In progress</option>
            <option value = 'Sample'>Sample</option>
            <option value = 'inactive'>inactive</option>
            <option value = 'active'>active</option>
          </select>
          <select onChange = {(e) => this.setState({ difficulty: e.target.value })} className = {this.state.difficulty === 'Easy' ? 'block fixed accent' : this.state.difficulty === 'Medium' ? 'block fixed in-progress' : this.state.difficulty === 'Hard' ? 'warning block fixed' : 'accent block fixed'}>
            <option value = 'Easy'>Easy</option>
            <option value = 'Medium'>Medium</option>
            <option value = 'Hard'>Hard</option>
          </select>
          <input
            placeholder = 'Title'
            onChange = {(e) => this.setState({title: e.target.value})}
            className = 'block fixed'
            value = {this.state.title}
          />
          <div className = 'block fixed'>
            Topics
            <div className = 'row flex-wrap'>
              <button onClick = {(ev) => this.setTopics(ev, 'Design a product')} className = {this.state.topics.indexOf('Design a product') >= 0 ? 'accent block round' : 'block round'}>Design a product</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Improve a product')} className = {this.state.topics.indexOf('Improve a product') >= 0 ? 'accent block round' : 'block round'}>Improve a product</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Favorite product')} className = {this.state.topics.indexOf('Favorite product') >= 0 ? 'accent block round' : 'block round'}>Favorite product</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Fermi')} className = {this.state.topics.indexOf('Fermi') >= 0 ? 'accent block round' : 'block round'}>Fermi</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Technical')} className = {this.state.topics.indexOf('Technical') >= 0 ? 'accent block round' : 'block round'}>Technical</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Strategy')} className = {this.state.topics.indexOf('Strategy') >= 0 ? 'accent block round' : 'block round'}>Strategy</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Behavioral')} className = {this.state.topics.indexOf('Behavioral') >= 0 ? 'accent block round' : 'block round'}>Behavioral</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Measure success')} className = {this.state.topics.indexOf('Measure success') >= 0 ? 'accent block round' : 'block round'}>Measure success</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Diagnosis')} className = {this.state.topics.indexOf('Diagnosis') >= 0 ? 'accent block round' : 'block round'}>Diagnosis</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Expansion')} className = {this.state.topics.indexOf('Expansion') >= 0 ? 'accent block round' : 'block round'}>Expansion</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Take-home assignment')} className = {this.state.topics.indexOf('Take-home assignment') >= 0 ? 'accent block round' : 'block round'}>Take-home assignment</button>
            </div>
          </div>
          <div className = 'block fixed'>
            Companies
            <div className = 'row flex-wrap'>
              {
                this.state.companies.map(company => {
                  return <button onClick = {(ev) => this.setCompanies(ev, company.name)} className = {this.state.selectedCompanies.indexOf(company.name) >= 0 ? 'accent block round' : 'block round'}>{company.name}</button>
                })
              }
            </div>
          </div>
          <textarea
            placeholder = 'Problem'
            onChange = {(e) => this.setState({problem: e.target.value})}
            className = 'block fixed'
            value = {this.state.problem}
            rows = '5'
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Create problem</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Status: {this.state.status}</p>
            <p className = 'small thin'>Difficulty: {this.state.difficulty}</p>
            <p className = 'small thin'>Title: {this.state.title}</p>
            <p className = 'small thin'>Topics: {this.state.topics.map(topic => {
              return <button className = 'fixed round block accent'>{topic}</button>
            })}</p>
            <p className = 'small thin'>Companies: {this.state.selectedCompanies.map(company => {
              return <button className = 'fixed round block accent'>{company}</button>
            })}</p>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Problem</p>
            <div className = 'preview' dangerouslySetInnerHTML = {this.createMarkup(this.state.problem)} />
          </div>
        </div>
      </div>
    )
  }
}