import React, { PureComponent } from 'react'
import moment from 'moment'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'

export default class CreateCoupon extends PureComponent {
  state = {
    error: '',
    affiliate: null,
    defaulted: false,
    name: '',
    code: '',
    valid: false,
    redemptions: [],
    hasExpiry: false,
    expiry: 2240542800,
    hasLimit: false,
    limit: 1,
    month: '12',
    day: '31',
    year: '2040',
    hour: '12',
    minute: '00',
    clock: 'AM',
    discountType: 'percent',
    amountOff: 5,
    percentOff: 0,
    subscriptionType: 'both',
    domain: '',
    duration: 'once',
    durationInMonths: null,
    showModal: false,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchAffiliate()
    this.setState({
      loading: false,
    })
  }
  fetchAffiliate = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/affiliates/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { affiliate, auth, error } = await res.json()
    if(auth) {
      this.setState({
        id: affiliate.id,
        affiliate,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  createCoupon = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/create/coupon`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        affiliateId: this.state.id,
        name: this.state.name,
        code: this.state.code,
        valid: this.state.valid,
        subscriptionType: this.state.subscriptionType,
        hasExpiry: this.state.hasExpiry,
        expiry: this.state.expiry,
        hasLimit: this.state.hasLimit,
        limit: this.state.limit,
        amountOffInCents: this.state.amountOff*100,
        percentOff: this.state.percentOff,
        defaulted: this.state.defaulted,
        domain: this.state.domain,
        duration: this.state.duration,
        durationInMonths: this.state.duration === 'repeating' ? parseInt(this.state.durationInMonths) : null
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        showModal: true,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  setValidity = (validity) => {
    const valid = validity === 'false' ? false : true
    this.setState({
      valid
    })
  }
  setDefault = (defaulted) => {
    const d = defaulted === 'false' ? false : true
    this.setState({
      defaulted: d
    })
  }
  setHasExpiry = (value) => {
    const hasExpiry = value === 'false' ? false : true
    this.setState({
      hasExpiry
    })
  }
  setHasLimit = (value) => {
    const hasLimit = value === 'false' ? false : true
    this.setState({
      hasLimit
    })
  }
  setExpiry = async () => {
    const realHour = this.state.hour === 12 && this.state.clock === 'AM' ? '00' : this.state.clock === 'AM' ? this.state.hour : this.state.hour === 12 ? '12' : parseInt(this.state.hour)+12
    const dateToParse = `${this.state.year}-${this.state.month}-${this.state.day}T${realHour}:${this.state.minute}`
    const date = await moment(dateToParse).unix()
    this.setState({
      expiry: date
    })
  }
  setDatetime = async (section, value) => {
    if(section === 'month') {
      await this.setState({
        month: value
      })
    } else if(section === 'day') {
      const fixed = value < 10 ? `0${value}` : value
      await this.setState({
        actualDay: value,
        day: fixed
      })
    } else if(section === 'year') {
      await this.setState({
        year: value
      })
    } else if(section === 'hour') {
      await this.setState({
        hour: value
      })
    } else if(section === 'minute') {
      await this.setState({
        minute: value
      })
    } else if(section === 'clock') {
      await this.setState({
        clock: value
      })
    }
    await this.setExpiry()
  }
  setDuration = (duration) => {
    this.setState({
      duration
    })
    if(duration !== 'repeating') {
      this.setState({
        durationInMonths: null
      })
    }
  }
  closeModal = () => {
    if(this.state.affiliate.ref === 'dpp') {
      this.props.history.push('/promotions')
    } else {
      this.props.history.push(`/affiliates/${this.state.id}`)
    }
  }
  render() {
    const days = ['1','2','3','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
    const years = ['2020','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030','2031','2032','2033','2034','2035','2036','2037','2038','2039','2040']
    const hours = ['01','02','03','05','06','07','08','09','10','11','12']
    const minutes = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59','60']
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Successfully created coupon.`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.createCoupon}>
          <select value = {this.state.valid} onChange = {(e) => this.setValidity(e.target.value)} className = {this.state.valid ? 'block fixed' : 'block fixed inactive'}>
            <option value = {false}>Invalid</option>
            <option value = {true}>Valid</option>
          </select>
          <select value = {this.state.defaulted} onChange = {(e) => this.setDefault(e.target.value)} className = 'block fixed'>
            <option value = {false}>Not default</option>
            <option value = {true}>Default</option>
          </select>
          <input
            placeholder = 'Name'
            onChange = {(e) => this.setState({ name: e.target.value })}
            className = 'block fixed'
            value = {this.state.name}
          />
          <input
            placeholder = 'Code'
            onChange = {(e) => this.setState({ code: e.target.value })}
            className = 'block fixed'
            value = {this.state.code}
          />
          <select value = {this.state.hasExpiry} onChange = {(e) => this.setHasExpiry(e.target.value)} className = {this.state.hasExpiry ? 'block fixed' : 'block fixed inactive'}>
            <option value = {false}>No expiry</option>
            <option value = {true}>Has expiry</option>
          </select>
          {
            this.state.hasExpiry ? (
              <div className = 'column'>
                <div className = 'row'>
                  <select value = {this.state.month} onChange = {(e) => this.setDatetime('month', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    <option value = '01'>January</option>
                    <option value = '02'>February</option>
                    <option value = '03'>March</option>
                    <option value = '04'>April</option>
                    <option value = '05'>May</option>
                    <option value = '06'>June</option>
                    <option value = '07'>July</option>
                    <option value = '08'>August</option>
                    <option value = '09'>September</option>
                    <option value = '10'>October</option>
                    <option value = '11'>November</option>
                    <option value = '12'>December</option>
                  </select>
                  <select value = {this.state.actualDay} onChange = {(e) => this.setDatetime('day', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    {
                      days.map(day => {
                        return (
                          <option value = {day}>{day}</option>
                        )
                      })
                    }
                  </select>
                  <select value = {this.state.year} onChange = {(e) => this.setDatetime('year', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    {
                      years.map(year => {
                        return (
                          <option value = {year}>{year}</option>
                        )
                      })
                    }
                  </select>
                  <select value = {this.state.hour} onChange = {(e) => this.setDatetime('hour', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    {
                      hours.map(hour => {
                        return (
                          <option value = {hour}>{hour}</option>
                        )
                      })
                    }
                  </select>
                  <select value = {this.state.minute} onChange = {(e) => this.setDatetime('minute', e.target.value)} className = 'fixed wrapper block remove-wrapper-padding'>
                    {
                      minutes.map(minute => {
                        return (
                          <option value = {minute}>{minute}</option>
                        )
                      })
                    }
                  </select>
                  <select value = {this.state.clock} onChange = {(e) => this.setDatetime('clock', e.target.value)} className = 'fixed wrapper block'>
                    <option value = 'AM'>AM</option>
                    <option value = 'PM'>PM</option>
                  </select>
                </div>
              </div>
            ) : null
          }
          <select value = {this.state.hasLimit} onChange = {(e) => this.setHasLimit(e.target.value)} className = {this.state.hasLimit ? 'block fixed' : 'block fixed inactive'}>
            <option value = {false}>No limit</option>
            <option value = {true}>Has limit</option>
          </select>
          {
            this.state.hasLimit ? (
              <div className = 'block fixed'>
                Redeemable
                <input
                  placeholder = '15'
                  type = 'number'
                  className = 'block fixed wrapper remove-wrapper-padding discount-input-width'
                  onChange = {(e) => this.setState({limit: parseInt(e.target.value)})}
                  value = {this.state.limit}
                />
                times
              </div>
            ) : null
          }
          <select value = {this.state.discountType} onChange = {(e) => this.setState({ discountType: e.target.value })} className = 'block fixed'>
            <option value = 'percent'>Percentage discount</option>
            <option value = 'amount'>Amount discount</option>
          </select>
          {
            this.state.discountType === 'percent' ? (
              <div className = 'block fixed'>
                <input
                  placeholder = '15'
                  type = 'number'
                  className = 'block fixed wrapper remove-wrapper-padding discount-input-width'
                  onChange = {(e) => this.setState({ percentOff: parseFloat(e.target.value), amountOff: null })}
                  value = {this.state.percentOff}
                />
                % off
              </div>
            ) : (
              <div className = 'block fixed'>
                $
                <input
                  placeholder = '5'
                  type = 'number'
                  className = 'block fixed wrapper remove-wrapper-padding discount-input-width'
                  onChange = {(e) => this.setState({ amountOff: parseFloat(e.target.value), percentOff: null })}
                  value = {this.state.amountOff}
                />
                USD off
              </div>
            )
          }
          <select value = {this.state.subscriptionType} onChange = {(e) => this.setState({ subscriptionType: e.target.value })} className = 'block fixed'>
            <option value = 'monthly'>Monthly discount only</option>
            <option value = 'yearly'>Yearly discount only</option>
            <option value = 'both'>Offered to both monthly and yearly</option>
          </select>
          <select value = {this.state.duration} onChange = {(e) => this.setDuration(e.target.value)} className = 'block fixed'>
            <option value = 'once'>Only applied once</option>
            <option value = 'repeating'>Repeats for a certain number of months</option>
            <option value = 'forever'>Repeats forever</option>
          </select>
          {
            this.state.duration === 'repeating' ? (
              <div className = 'block fixed'>
                <input
                  placeholder = '15'
                  type = 'number'
                  className = 'block fixed wrapper remove-wrapper-padding discount-input-width'
                  onChange = {(e) => this.setState({ durationInMonths: e.target.value })}
                  value = {this.state.durationInMonths}
                />
                months discounted
              </div>
            ) : null
          }
          <input
            placeholder = 'Domain'
            onChange = {(e) => this.setState({ domain: e.target.value })}
            className = 'block fixed'
            value = {this.state.domain}
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Create coupon</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Affiliate: {this.state.affiliate.name}</p>
            <p className = 'small thin'>Valid: {this.state.valid ? 'Valid' : 'Invalid'}</p>
            <p className = 'small thin'>Defaulted: {this.state.defaulted ? 'Default' : 'Not Default'}</p>
            <p className = 'small thin'>Name: {this.state.name}</p>
            <p className = 'small thin'>Code: {this.state.code}</p>
            <p className = 'small thin'>Expiry: {this.state.hasExpiry ? moment.unix(this.state.expiry).format('LLLL') : 'No expiry'}</p>
            <p className = 'small thin'>Limit: {this.state.hasLimit ? this.state.limit : 'No limit'}</p>
            <p className = 'small thin'>Domain: {this.state.domain}</p>
            <p className = 'small thin'>Duration: {this.state.duration}</p>
            <p className = 'small thin'>Duration in months: {this.state.durationInMonths}</p>
            <p className = 'small thin'>
              Offer: {this.state.discountType === 'percent' ? `${this.state.percentOff}% off ` : `$${this.state.amountOff}USD off `}
              {this.state.subscriptionType === 'monthly' ? 'for monthly only.' : this.state.subscriptionType === 'yearly' ? 'for yearly only.' : 'for both monthly and yearly.'}
            </p>
          </div>
        </div>
      </div>
    )
  }
}