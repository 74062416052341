import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'
import CouponCard from '@/components/CouponCard'
import AffiliateUserCard from '@/components/AffiliateUserCard'

export default class Affiliate extends PureComponent {
  state = {
    error: '',
    id: '',
    name: '',
    ref: '',
    type: '',
    status: '',
    description: '',
    coupons: [],
    affiliateUsers: [],
    showModal: false,
    locked: false,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchAffiliate()
    await this.fetchCoupons()
    await this.fetchAffiliateUsers()
    this.setState({
      loading: false,
      locked: this.state.ref === 'dpp' ? true : false
    })
  }
  fetchAffiliate = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/affiliates/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { affiliate, auth, error } = await res.json()
    if(auth) {
      this.setState({
        id: affiliate.id,
        name: affiliate.name,
        ref: affiliate.ref,
        type: affiliate.type,
        status: affiliate.status,
        description: affiliate.description,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchCoupons = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/affiliates/${id}/coupons`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { coupons, auth, error } = await res.json()
    if(auth) {
      this.setState({
        coupons
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchAffiliateUsers = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/affiliateUsers/affiliate/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { affiliateUsers, auth, error } = await res.json()
    if(auth) {
      this.setState({
        affiliateUsers
      })
    } else {
      this.setState({
        error
      })
    }
  }
  updateAffiliate = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/update/affiliate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        id: this.state.id,
        name: this.state.name,
        ref: this.state.ref,
        status: this.state.status,
        type: this.state.type,
        description: this.state.description,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        error,
        showModal: true,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  closeModal = () => {
    this.setState({
      showModal: false
    })
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Affiliate updated.`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.updateAffiliate}>
          {
            this.state.locked ?
              null : (
              <select value = {this.state.status} onChange = {(e) => this.setState({ status: e.target.value })} className = {this.state.status === 'inactive' ? 'block fixed inactive' : this.state.status === 'active' ? 'block fixed' : 'block fixed'}>
                <option value = 'inactive'>inactive</option>
                <option value = 'active'>active</option>
              </select>
            )
          }
          {
            this.state.locked ?
              null :
              <input
                placeholder = 'Name'
                onChange = {(e) => this.setState({name: e.target.value})}
                className = 'block fixed'
                value = {this.state.name}
              />
          }
          {
            this.state.locked ?
              null :
              <input
                placeholder = 'Ref'
                onChange = {(e) => this.setState({ref: e.target.value})}
                className = 'block fixed'
                value = {this.state.ref}
              />
          }
          {
            this.state.locked ?
              null :
              <select value = {this.state.type} onChange = {(e) => this.setState({ type: e.target.value })} className = 'block fixed'>
                <option value = 'business'>Business</option>
                <option value = 'affiliate'>Affiliate</option>
              </select>
          }
          <input
            placeholder = 'Description'
            onChange = {(e) => this.setState({description: e.target.value})}
            className = 'block fixed'
            value = {this.state.description}
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Save</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Status: {this.state.status}</p>
            <p className = 'small thin'>Name: {this.state.name}</p>
            <p className = 'small thin'>Ref: {this.state.ref}</p>
            <p className = 'small thin'>Type: {this.state.type}</p>
            <p className = 'small thin'>Description: {this.state.description}</p>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Coupons</p>
            <div className = 'column'>
              <div className = 'row'>
                <button className = 'block round fixed'>Valid</button>
                <button className = 'block round in-progress fixed'>Default</button>
                <button className = 'block round sample fixed'>Expired</button>
                <button className = 'block round inactive fixed'>Invalid</button>
              </div>
              <button className = 'accent block text-align-left'>
                <NavLink to = {`/affiliates/${this.state.id}/coupons/create-new`}>
                  <p className = 'no-margin'>Create Coupon</p>
                </NavLink>
              </button>
              {
                this.state.coupons.map(coupon => {
                  return <CouponCard coupon = {coupon} affiliateId = {this.state.id} />
                })
              }
            </div>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Affiliated Users</p>
            <div className = 'column'>
              <div className = 'row'>
                <button className = 'block round in-progress fixed'>grant</button>
                <button className = 'block round fixed'>coupon</button>
                <button className = 'block round inactive fixed'>inactive</button>
              </div>
              <button className = 'accent block text-align-left'>
                <NavLink to = {`/affiliates/${this.state.id}/users/create-new`}>
                  <p className = 'no-margin'>Create Affiliated User</p>
                </NavLink>
              </button>
              {
                this.state.affiliateUsers.map(affiliateUser => {
                  return <AffiliateUserCard affiliateUser = {affiliateUser} affiliate = {affiliateUser.affiliate} user = {affiliateUser.user} affiliateId = {this.state.id} />
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}