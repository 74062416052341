import React, { PureComponent } from 'react'

import { STORAGE_KEY } from '@/lib/constants'

import BasicModal from '@/components/BasicModal'

export default class CreateCompany extends PureComponent {
  state = {
    error: '',
    name: '',
    status: 'inactive',
    showModal: false,
  }
  createCompany = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/create/company`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        name: this.state.name,
        status: this.state.status,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        showModal: true
      })
    } else {
      this.setState({
        error
      })
    }
  }
  closeModal = () => {
    this.props.history.push('/companies')
  }
  render() {
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Successfully uploaded!`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.createCompany}>
          <select onChange = {(e) => this.setState({ status: e.target.value })} className = {this.state.status === 'inactive' ? 'block fixed inactive' : 'block fixed'}>
            <option value = 'inactive'>inactive</option>
            <option value = 'active'>active</option>
          </select>
          <input
            placeholder = 'Company name'
            onChange = {(e) => this.setState({name: e.target.value})}
            className = 'block fixed'
            value = {this.state.name}
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Create company</button>
        </form>
        <div className = 'flex-3 margin'>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Status: {this.state.status}</p>
            <p className = 'small thin'>Company: {this.state.name}</p>
          </div>
        </div>
      </div>
    )
  }
}