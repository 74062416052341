import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

export default class PostCard extends PureComponent {
  render() {
    if(this.props.post.status === 'inactive') {
      return (
        <button className = 'block text-align-left inactive'>
          <NavLink className = 'row justify-content-space-between' to = {`/posts/${this.props.post.id}`}>
            <p className = 'no-margin'>{this.props.post.title}</p>
            <p className = 'no-margin'>{this.props.post.author.user.firstName} {this.props.post.author.user.lastName}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.post.status === 'In progress') {
      return (
        <button className = 'block text-align-left in-progress'>
          <NavLink className = 'row justify-content-space-between' to = {`/posts/${this.props.post.id}`}>
            <p className = 'no-margin'>{this.props.post.title}</p>
            <p className = 'no-margin'>{this.props.post.author.user.firstName} {this.props.post.author.user.lastName}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.post.status === 'active') {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/posts/${this.props.post.id}`}>
            <p className = 'no-margin'>{this.props.post.title}</p>
            <p className = 'no-margin'>{this.props.post.author.user.firstName} {this.props.post.author.user.lastName}</p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}