import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import CouponCard from '@/components/CouponCard'

export default class Promotions extends PureComponent {
  state = {
    error: '',
    coupons: [],
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchPromotions()
    await this.fetchAffiliate()
    this.setState({
      loading: false
    })
  }
  fetchAffiliate = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/affiliates/dpp`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { affiliate, auth, error } = await res.json()
    if(auth) {
      this.setState({
        affiliate
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchPromotions = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/coupons/dpp`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { coupons, auth, error } = await res.json()
    if(auth) {
      this.setState({
        coupons
      })
    } else {
      this.setState({
        error
      })
    }
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'column'>
        <div className = 'row'>
          <button className = 'block round fixed'>Valid</button>
          <button className = 'block round in-progress fixed'>Default</button>
          <button className = 'block round sample fixed'>Expired</button>
          <button className = 'block round inactive fixed'>Invalid</button>
        </div>
        <button className = 'accent block text-align-left'>
          <NavLink to = {`/affiliates/${this.state.affiliate.id}/coupons/create-new`}>
            <p className = 'no-margin'>Create Promotion</p>
          </NavLink>
        </button>
        {
          this.state.coupons.map(coupon => {
            return <CouponCard coupon = {coupon} affiliateId = {this.state.affiliate.id} />
          })
        }
      </div>
    )
  }
}