import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

export default class AffiliateUserCard extends PureComponent {
  render() {
    if(this.props.affiliateUser.status === 'active' && this.props.affiliateUser.dealType === 'grant') {
      return (
        <button className = 'block text-align-left in-progress'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/affiliateUser/${this.props.affiliateUser.id}`}>
            <p className = 'no-margin'>{this.props.user.email} ({this.props.user.username})</p>
            <p className = 'no-margin'>{this.props.affiliate.name} • {moment(this.props.affiliateUser.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.affiliateUser.status === 'active' && this.props.affiliateUser.dealType === 'coupon') {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/affiliateUser/${this.props.affiliateUser.id}`}>
            <p className = 'no-margin'>{this.props.user.email} ({this.props.user.username})</p>
            <p className = 'no-margin'>{this.props.affiliate.name} • {moment(this.props.affiliateUser.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.affiliateUser.status === 'inactive') {
      return (
        <button className = 'block text-align-left inactive'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/affiliateUser/${this.props.affiliateUser.id}`}>
            <p className = 'no-margin'>{this.props.user.email} ({this.props.user.username})</p>
            <p className = 'no-margin'>{this.props.affiliate.name} • {moment(this.props.affiliateUser.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}