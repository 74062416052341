import React, { PureComponent } from 'react'

import Lost from '@/assets/lost.svg'

export default class E404 extends PureComponent {
	render() {
		return (
			<div className = 'column align-items-center'>
				<img
					src = {Lost}
					alt = 'Lost'
					className = 'error-image'
				/>
				<h1>Hmm, you seem to be lost!</h1>
			</div>
		)
	}
}