import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

export default class CommentCard extends PureComponent {
  render() {
    if(this.props.comment.status === 'inactive') {
      return (
        <button className = 'block text-align-left inactive'>
          <NavLink className = 'row justify-content-space-between' to = {`/comments/${this.props.comment.id}`}>
            <p className = 'no-margin'>{this.props.comment.problem.title} written by {this.props.comment.user.email}</p>
            <p className = 'no-margin'>{this.props.comment.commentPraises.length} praise • {moment(this.props.comment.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.comment.status === 'DPP Solution') {
      return (
        <button className = 'block text-align-left sample'>
          <NavLink className = 'row justify-content-space-between' to = {`/comments/${this.props.comment.id}`}>
            <p className = 'no-margin'>{this.props.comment.problem.title} written by {this.props.comment.user.email}</p>
            <p className = 'no-margin'>{this.props.comment.commentPraises.length} praise • {moment(this.props.comment.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.comment.status === 'Verified') {
      return (
        <button className = 'block text-align-left accent'>
          <NavLink className = 'row justify-content-space-between' to = {`/comments/${this.props.comment.id}`}>
            <p className = 'no-margin'>{this.props.comment.problem.title} written by {this.props.comment.user.email}</p>
            <p className = 'no-margin'>{this.props.comment.commentPraises.length} praise • {moment(this.props.comment.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(!this.props.comment.parentId) {
      return (
        <button className = 'block text-align-left in-progress'>
          <NavLink className = 'row justify-content-space-between' to = {`/comments/${this.props.comment.id}`}>
            <p className = 'no-margin'>{this.props.comment.problem.title} written by {this.props.comment.user.email}</p>
            <p className = 'no-margin'>{this.props.comment.commentPraises.length} praise • {moment(this.props.comment.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.comment.status === 'active') {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/comments/${this.props.comment.id}`}>
            <p className = 'no-margin'>{this.props.comment.problem.title} written by {this.props.comment.user.email}</p>
            <p className = 'no-margin'>{this.props.comment.commentPraises.length} praise • {moment(this.props.comment.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}