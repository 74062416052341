import React, { PureComponent } from 'react'
import moment from 'moment'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'

import './index.css'

export default class Overview extends PureComponent {
	state = {
		loading: true,
		error: null,
		subscribers: 0,
		premiums: 0,
		mrr: 0,
		date: new Date()
	}
	componentDidMount = async () => {
		await this.fetchOverview()
		this.setState({
			loading: false,
		})
	}
	fetchOverview = async () => {
		const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/analytics/overview`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { analytics, auth, error } = await res.json()
    if(auth) {
      this.setState({
        subscribers: analytics.subscribers,
        premiums: analytics.premiums,
        mrr: analytics.mrr
      })
    } else {
      this.setState({
        error
      })
    }
	}
	render() {
		if(this.state.loading) {
			return <Spinner />
		}
		return (
			<div className = 'column text-align-center'>
				<h4>As of <strong>{moment(this.state.date).format('LLLL')}</strong></h4>
				<div className = 'overview-core-analytics'>
					<div className = 'overview-core-analytics-box'>
						<h3 className = 'margin'>Subscribers</h3>
						<h3 className = 'margin core-analytics'>{this.state.subscribers}</h3>
					</div>
					<div className = 'overview-core-analytics-box'>
						<h3 className = 'margin'>Premiums</h3>
						<h3 className = 'margin core-analytics'>{this.state.premiums}</h3>
					</div>
					<div className = 'overview-core-analytics-box'>
						<h3 className = 'margin'>MRR</h3>
						<h3 className = 'margin core-analytics'>${this.state.mrr}</h3>
					</div>
				</div>
			</div>
		)
	}
}