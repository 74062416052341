import React, { PureComponent } from 'react'

import { STORAGE_KEY } from '@/lib/constants'

import BasicModal from '@/components/BasicModal'

export default class CreateAffiliate extends PureComponent {
  state = {
    error: '',
    name: '',
    ref: '',
    type: 'business',
    description: '',
    status: 'inactive',
    showModal: false,
  }
  createAffiliate = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/create/affiliate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        name: this.state.name,
        ref: this.state.ref,
        type: this.state.type,
        description: this.state.description,
        status: this.state.status,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        showModal: true,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  closeModal = () => {
    this.props.history.push('/affiliates')
  }
  render() {
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Affiliate created.`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.createAffiliate}>
          <select value = {this.state.status} onChange = {(e) => this.setState({ status: e.target.value })} className = {this.state.status === 'inactive' ? 'block fixed inactive' : this.state.status === 'active' ? 'block fixed' : 'block fixed'}>
            <option value = 'inactive'>inactive</option>
            <option value = 'active'>active</option>
          </select>
          <input
            placeholder = 'Name'
            onChange = {(e) => this.setState({name: e.target.value})}
            className = 'block fixed'
            value = {this.state.name}
          />
          <input
            placeholder = 'Ref'
            onChange = {(e) => this.setState({ref: e.target.value})}
            className = 'block fixed'
            value = {this.state.ref}
          />
          <select value = {this.state.type} onChange = {(e) => this.setState({ type: e.target.value })} className = 'block fixed'>
            <option value = 'business'>Business</option>
            <option value = 'affiliate'>Affiliate</option>
          </select>
          <input
            placeholder = 'Description'
            onChange = {(e) => this.setState({description: e.target.value})}
            className = 'block fixed'
            value = {this.state.description}
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Create affiliate</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Status: {this.state.status}</p>
            <p className = 'small thin'>Name: {this.state.name}</p>
            <p className = 'small thin'>Ref: {this.state.ref}</p>
            <p className = 'small thin'>Type: {this.state.type}</p>
            <p className = 'small thin'>Description: {this.state.description}</p>
          </div>
        </div>
      </div>
    )
  }
}