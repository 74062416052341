import React, { PureComponent } from 'react'

import './index.css'

export default class MicroHeader extends PureComponent {
	render() {
		return (
			<div className = 'microheader'>
				<div className = 'row'>
					<p className = 'bold'>{this.props.route}</p>
				</div>
			</div>
		)
	}
}