import React, { PureComponent } from 'react'
import marked from 'marked'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'

export default class CreateComment extends PureComponent {
  state = {
    error: '',
    status: 'inactive',
    comment: '',
    showModal: false,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchProblem()
    this.setState({
      loading: false,
    })
  }
  fetchProblem = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const problemId = this.props.match.params.problemId
    const res = await fetch(`/api/admins/problems/${problemId}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { problem, auth, error } = await res.json()
    if(auth) {
      this.setState({
        problem: problem.problem,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  createComment = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const problemId = this.props.match.params.problemId
    const res = await fetch(`/api/admins/create/comment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        problemId,
        status: this.state.status,
        comment: this.state.comment,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        showModal: true
      })
    } else {
      this.setState({
        error
      })
    }
  }
  closeModal = () => {
    const problemId = this.props.match.params.problemId
    this.props.history.push(`/problems/${problemId}`)
  }
  createMarkup = (content) => {
    if(content) {
      const html = marked(content)
      return { __html: html }
    } else {
      return { __html: ' '}
    }
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Successfully uploaded!`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.createComment}>
          <select value = {this.state.status} onChange = {(e) => this.setState({ status: e.target.value })} className = {this.state.status === 'DPP Solution' ? 'block fixed sample' : 'block fixed inactive'}>
            <option value = 'inactive'>inactive</option>
            <option value = 'DPP Solution'>DPP Solution</option>
          </select>
          <textarea
            placeholder = 'Comment'
            onChange = {(e) => this.setState({comment: e.target.value})}
            className = 'block fixed'
            value = {this.state.comment}
            rows = '5'
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Create comment</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Status: {this.state.status}</p>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Problem</p>
            <div className = 'preview' dangerouslySetInnerHTML = {this.createMarkup(this.state.problem)} />
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Comment</p>
            <div className = 'preview' dangerouslySetInnerHTML = {this.createMarkup(this.state.comment)} />
          </div>
        </div>
      </div>
    )
  }
}