import React, { PureComponent } from 'react'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import UserCard from '@/components/UserCard'

export default class UsersByRef extends PureComponent {
  state = {
    error: '',
    referrals: [],
    subscribers: 230,
    premiums: 4,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchUsers()
    this.setState({
      loading: false
    })
  }
  fetchUsers = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const ref = this.props.match.params.ref
    const res = await fetch(`/api/admins/users/ref/${ref}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { users, auth, error } = await res.json()
    if(auth) {
      const premiums = users.filter((obj) => obj.type === 'premium').length;
      this.setState({
        users,
        subscribers: users.length,
        premiums,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'column'>
        <div className = 'overview-core-analytics text-align-center'>
          <div className = 'overview-core-analytics-box'>
            <h3 className = 'margin'>Ref</h3>
            <h3 className = 'margin core-analytics'>{this.props.match.params.ref}</h3>
          </div>
          <div className = 'overview-core-analytics-box'>
            <h3 className = 'margin'>Subscribers</h3>
            <h3 className = 'margin core-analytics'>{this.state.subscribers}</h3>
          </div>
          <div className = 'overview-core-analytics-box'>
            <h3 className = 'margin'>Premiums</h3>
            <h3 className = 'margin core-analytics'>{this.state.premiums}</h3>
          </div>
        </div>
        <div className = 'row'>
          <button className = 'block round in-progress fixed'>Premium</button>
          <button className = 'block round fixed'>Free</button>
          <button className = 'block round sample fixed'>Delinquent</button>
          <button className = 'block round inactive fixed'>Churned</button>
        </div>
        {
          this.state.users.map(user => {
            return <UserCard user = {user} />
          })
        }
      </div>
    )
  }
}