import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

export default class CouponCard extends PureComponent {
  render() {
    if(!this.props.coupon.valid) {
      return (
        <button className = 'block text-align-left inactive'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/${this.props.affiliateId}/coupons/${this.props.coupon.id}`}>
            <p className = 'no-margin'>{this.props.coupon.name} ({this.props.coupon.code})</p>
            <p className = 'no-margin'>{this.props.coupon.redemptions.length} redemptions • {moment(this.props.coupon.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.coupon.expiry && this.props.coupon.expiry < moment().unix()) {
      return (
        <button className = 'block text-align-left sample'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/${this.props.affiliateId}/coupons/${this.props.coupon.id}`}>
            <p className = 'no-margin'>{this.props.coupon.name} ({this.props.coupon.code})</p>
            <p className = 'no-margin'>{this.props.coupon.redemptions.length} redemptions • {moment(this.props.coupon.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.coupon.default) {
      return (
        <button className = 'block text-align-left in-progress'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/${this.props.affiliateId}/coupons/${this.props.coupon.id}`}>
            <p className = 'no-margin'>{this.props.coupon.name} ({this.props.coupon.code})</p>
            <p className = 'no-margin'>{this.props.coupon.redemptions.length} redemptions • {moment(this.props.coupon.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.coupon.valid) {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/affiliates/${this.props.affiliateId}/coupons/${this.props.coupon.id}`}>
            <p className = 'no-margin'>{this.props.coupon.name} ({this.props.coupon.code})</p>
            <p className = 'no-margin'>{this.props.coupon.redemptions.length} redemptions • {moment(this.props.coupon.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}