import React, { PureComponent } from 'react'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import CommentCard from '@/components/CommentCard'

export default class Comments extends PureComponent {
  state = {
    error: '',
    comments: [],
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchComments()
    this.setState({
      loading: false
    })
  }
  fetchComments = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/comments`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { comments, auth, error } = await res.json()
    if(auth) {
      this.setState({
        comments
      })
    } else {
      this.setState({
        error
      })
    }
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'column'>
        <div className = 'row'>
          <button className = 'block round in-progress fixed'>Root solution</button>
          <button className = 'block round sample fixed'>DPP Solution</button>
          <button className = 'block round accent fixed'>Verified Solution</button>
          <button className = 'block round fixed'>active</button>
          <button className = 'block round inactive fixed'>inactive</button>
        </div>
        {
          this.state.comments.map(comment => {
            return <CommentCard comment = {comment} />
          })
        }
      </div>
    )
  }
}