import React, { PureComponent } from 'react'

import { STORAGE_KEY } from '@/lib/constants'

import BasicModal from '@/components/BasicModal'

export default class CreateUser extends PureComponent {
  state = {
    error: '',
    email: null,
    referrer: null,
    showModal: false,
  }
  createUser = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/create/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        email: this.state.email,
        referrer: this.state.referrer,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        showModal: true,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  closeModal = () => {
    this.props.history.push('/users')
  }
  render() {
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`User created.`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.createUser}>
          <input
            placeholder = 'Email'
            onChange = {(e) => this.setState({email: e.target.value})}
            className = 'block fixed'
            value = {this.state.email}
          />
          <input
            placeholder = 'Referrer'
            onChange = {(e) => this.setState({referrer: e.target.value})}
            className = 'block fixed'
            value = {this.state.referrer}
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Create user</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Name: {this.state.name}</p>
            <p className = 'small thin'>Referrer: {this.state.referrer}</p>
          </div>
        </div>
      </div>
    )
  }
}