import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

export default class ReferralCard extends PureComponent {
  render() {
    if(!this.props.referral) {
      return (
        <button className = 'block text-align-left in-progress'>
          <div className = 'row justify-content-space-between'>
            <p className = 'no-margin'>{this.props.referral.referrer}</p>
            <p className = 'no-margin'>{this.props.referral.count} used • </p>
          </div>
        </button>
      )
    }
    if(this.props.referral) {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/referrals/${this.props.referral.referrer}`}>
            <p className = 'no-margin'>{this.props.referral.referrer}</p>
            <p className = 'no-margin'>{this.props.referral.count} used • </p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}