import React, { PureComponent } from 'react'

import './index.css'

export default class OptionModal extends PureComponent {
  render() {
    return (
      <div className = 'modal-overlay'>
        <div className = 'block fixed modal'>
          <h2>{this.props.text}</h2>
          <button onClick = {() => this.props.yesModal()} className = 'block warning'>{this.props.yesModalText}</button>
          <button onClick = {() => this.props.closeModal()} className = 'block accent'>Close</button>
        </div>
      </div>
    )
  }
}