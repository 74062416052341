import React, { PureComponent } from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import Navigator from '@/components/Navigator'
import MicroHeader from '@/components/MicroHeader'

import Login from '@/pages/Login'
import Logout from '@/pages/Logout'
import Homepage from '@/pages/Homepage'
import Users from '@/pages/Users'
import User from '@/pages/User'
import CreateUser from '@/pages/CreateUser'
import Problems from '@/pages/Problems'
import Problem from '@/pages/Problem'
import CreateProblem from '@/pages/CreateProblem'
import Comments from '@/pages/Comments'
import Comment from '@/pages/Comment'
import CreateComment from '@/pages/CreateComment'
import Posts from '@/pages/Posts'
import Post from '@/pages/Post'
import CreatePost from '@/pages/CreatePost'
import Companies from '@/pages/Companies'
import Company from '@/pages/Company'
import CreateCompany from '@/pages/CreateCompany'
import Affiliates from '@/pages/Affiliates'
import Affiliate from '@/pages/Affiliate'
import AffiliateUser from '@/pages/AffiliateUser'
import CreateAffiliate from '@/pages/CreateAffiliate'
import CreateGrant from '@/pages/CreateGrant'
import Promotions from '@/pages/Promotions'
import Coupon from '@/pages/Coupon'
import CreateCoupon from '@/pages/CreateCoupon'
import Referrals from '@/pages/Referrals'
import UsersByRef from '@/pages/UsersByRef'

import Overview from '@/pages/Overview'
import Growth from '@/pages/Growth'

import E404 from '@/pages/E404'
import E423 from '@/pages/E423'

import './main.css'

export default class App extends PureComponent {
  state = {
    user: null,
    route: null,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchUser()
    await this.setInitialRouteName()
    this.setState({
      loading: false
    })
  }
  fetchUser = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    if(DEMO_TOKEN) {
      const res = await fetch(`/api/admins/auth`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + DEMO_TOKEN,
          'x-access-token': DEMO_TOKEN
        }
      })
      const { admin, error } = await res.json()
      this.setState({
        user: admin,
        error,
      })
    } else {
      this.setState({
        user: null
      })
    }
  }
  handleLogin = async (token) => {
    await localStorage.setItem(STORAGE_KEY, token)
    await this.fetchUser()
  }
  handleLogout = async () => {
    await localStorage.removeItem(STORAGE_KEY)
    await this.fetchUser()
  }
  setInitialRouteName = async () => {
    let route = 'Admin'
    const href = window.location.href
    if(href.indexOf('users') >= 0) {
      route = 'Users'
    } else if(href.indexOf('problems') >= 0) {
      route = 'Problems'
    } else if(href.indexOf('comments') >= 0) {
      route = 'Comments'
    } else if(href.indexOf('posts') >= 0) {
      route = 'Posts'
    } else if(href.indexOf('companies') >= 0) {
      route = 'Companies'
    } else if(href.indexOf('affiliates') >= 0) {
      route = 'Affiliates'
    } else if(href.indexOf('promotions') >= 0) {
      route = 'Promotions'
    } else if (href.indexOf('referrals') >= 0) {
      route = 'Referrals'
    } else if(href.indexOf('feedback') >= 0) {
      route = 'Feedback'
    } else if(href.indexOf('analytics') >= 0) {
      route = 'Overview'
    } else if(href.indexOf('revenue') >= 0) {
      route = 'Revenue'
    } else if(href.indexOf('growth') >= 0) {
      route = 'Growth'
    } else if(href.indexOf('engagement') >= 0) {
      route = 'Engagement'
    } else if(href.indexOf('Retention') >= 0) {
      route = 'Retention'
    } else if(href.indexOf('Settings') >= 0) {
      route = 'Settings'
    }
    this.setState({
      route
    })
  }
  setRouteName = async (route) => {
    this.setState({
      route
    })
  }
  FlexRoute = ({ component: Component, ...rest }) => {
    return <Route
      {...rest}
      render = {props => <Component {...props} {...rest}/> }
    />
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    if(this.state.user) {
      return (
        <BrowserRouter>
          <div className = 'app'>
            <Navigator setRouteName = {this.setRouteName} user = {this.state.user} />
            <div className = 'full-width'>
              <MicroHeader route = {this.state.route} />
              <Switch>
                <Route exact path = '/' component = {Homepage} />
                <this.FlexRoute exact path = '/logout' component = {Logout} handleLogout = {this.handleLogout} />
                <Route exact path = '/users' component = {Users} />
                <Route exact path = '/users/create-new' component = {CreateUser} />
                <Route exact path = '/users/:id' component = {User} />
                <Route exact path = '/problems' component = {Problems} />
                <Route exact path = '/problems/create-new' component = {CreateProblem} />
                <Route exact path = '/problems/:id' component = {Problem} />
                <Route exact path = '/comments' component = {Comments} />
                <Route exact path = '/problems/:problemId/comments/create-new' component = {CreateComment} />
                <Route exact path = '/comments/:id' component = {Comment} />
                <Route exact path = '/posts' component = {Posts} />
                <Route exact path = '/posts/create-new' component = {CreatePost} />
                <Route exact path = '/posts/:id' component = {Post} />
                <Route exact path = '/companies' component = {Companies} />
                <Route exact path = '/companies/create-new' component = {CreateCompany} />
                <Route exact path = '/companies/:id' component = {Company} />
                <Route exact path = '/affiliates' component = {Affiliates} />
                <Route exact path = '/affiliates/create-new' component = {CreateAffiliate} />
                <Route exact path = '/affiliates/:id' component = {Affiliate} />
                <Route exact path = '/affiliates/:id/coupons' component = {E423} />
                <Route exact path = '/affiliates/:id/coupons/create-new' component = {CreateCoupon} />
                <Route exact path = '/affiliates/:id/users/create-new' component = {CreateGrant} />
                <Route exact path = '/affiliates/affiliateUser/:id' component = {AffiliateUser} />
                <Route exact path = '/affiliates/:affiliateId/coupons/:id' component = {Coupon} />
                <Route exact path = '/promotions' component = {Promotions} />
                <Route exact path = '/promotions/create-new' component = {E423} />
                <Route exact path = '/referrals' component = {Referrals} />
                <Route exact path = '/referrals/:ref' component = {UsersByRef} />
                <Route exact path = '/feedback' component = {E423} />
                <Route exact path = '/analytics' component = {Overview} />
                <Route exact path = '/revenue' component = {E423} />
                <Route exact path = '/growth' component = {Growth} />
                <Route exact path = '/engagement' component = {E423} />
                <Route exact path = '/retention' component = {E423} />
                <Route exact path = '/settings' component = {E423} />
                <Route component = {E404} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      )
    }
    return (
      <div className = 'app'>
        <Login fetchUser = {this.fetchUser} handleLogin = {this.handleLogin} />
      </div>
    )
  }
}
