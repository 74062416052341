import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

export default class UserCard extends PureComponent {
  render() {
    if(this.props.user.type === 'churned') {
      return (
        <button className = 'block text-align-left inactive'>
          <NavLink className = 'row justify-content-space-between' to = {`/users/${this.props.user.id}`}>
            <p className = 'no-margin'>{this.props.user.firstName} {this.props.user.lastName} ({this.props.user.username} • {this.props.user.email})</p>
            <p className = 'no-margin'>{this.props.user.referrer} • {moment(this.props.user.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.user.type === 'delinquent') {
      return (
        <button className = 'block text-align-left sample'>
          <NavLink className = 'row justify-content-space-between' to = {`/users/${this.props.user.id}`}>
            <p className = 'no-margin'>{this.props.user.firstName} {this.props.user.lastName} ({this.props.user.username} • {this.props.user.email})</p>
            <p className = 'no-margin'>{this.props.user.referrer} • {this.props.user.plan} • {moment(this.props.user.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.user.type === 'premium') {
      return (
        <button className = 'block text-align-left in-progress'>
          <NavLink className = 'row justify-content-space-between' to = {`/users/${this.props.user.id}`}>
            <p className = 'no-margin'>{this.props.user.firstName} {this.props.user.lastName} ({this.props.user.username} • {this.props.user.email})</p>
            <p className = 'no-margin'>{this.props.user.referrer} • {this.props.user.plan} • {moment(this.props.user.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.user.type === 'free') {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/users/${this.props.user.id}`}>
            <p className = 'no-margin'>{this.props.user.firstName} {this.props.user.lastName} ({this.props.user.username} • {this.props.user.email})</p>
            <p className = 'no-margin'>{this.props.user.referrer} • {moment(this.props.user.createdAt).format('MMM DD YYYY')}</p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}