import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'

export default class CompanyCard extends PureComponent {
  render() {
    if(this.props.company.status === 'inactive') {
      return (
        <button className = 'block text-align-left inactive'>
          <NavLink className = 'row justify-content-space-between' to = {`/companies/${this.props.company.id}`}>
            <p className = 'no-margin'>{this.props.company.name}</p>
            <p className = 'no-margin'>{moment(this.props.company.createdAt).format('LLLL')}</p>
          </NavLink>
        </button>
      )
    }
    if(this.props.company.status === 'active') {
      return (
        <button className = 'block text-align-left'>
          <NavLink className = 'row justify-content-space-between' to = {`/companies/${this.props.company.id}`}>
            <p className = 'no-margin'>{this.props.company.name}</p>
            <p className = 'no-margin'>{moment(this.props.company.createdAt).format('LLLL')}</p>
          </NavLink>
        </button>
      )
    }
    return null
  }
}