import React, { PureComponent } from 'react'
import moment from 'moment'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'
import OptionModal from '@/components/OptionModal'
import UserRedemptionsCard from '@/components/UserRedemptionsCard'
import AffiliateUserCard from '@/components/AffiliateUserCard'
import CommentCard from '@/components/CommentCard'

export default class User extends PureComponent {
  state = {
    error: '',
    id: null,
    email: null,
    redemptions: [],
    grants: [],
    comments: [],
    showModal: false,
    loading: true,
  }
  componentDidMount = async () => {
    await this.fetchUser()
    await this.fetchComments()
    await this.fetchRedemptions()
    await this.fetchGrants()
    this.setState({
      loading: false,
    })
  }
  fetchUser = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/users/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { user, auth, error } = await res.json()
    if(auth) {
      console.log(user)
      this.setState({
        id: user.id,
        email: user.email,
        type: user.type,
        verified: user.verified,
        subscribed: user.subscribed,
        nextPremiumProblem: user.nextPremiumProblem,
        nextProblem: user.nextProblem,
        nextGuide: user.nextGuide,
        firstName: user.firstName,
        lastName: user.lastName,
        linkedInStatus: user.linkedInStatus,
        about: user.about,
        preferences: user.preferences,
        emailPreferences: user.emailPreferences,
        stripeId: user.stripeId,
        plan: user.plan,
        referrer: user.referrer,
        username: user.username,
        createdAt: user.createdAt,
        lastProblemSentAt: user.lastProblemSentAt,
        lastGuideSentAt: user.lastGuideSentAt,
        upgradedAt: user.upgradedAt,
        downgradedAt: user.downgradedAt,
        unsubscribedAt: user.unsubscribedAt,
        revivedAt: user.revivedAt,
        snoozeUntil: user.snoozeUntil,
        subscriptions: user.subscriptions,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchComments = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/users/${id}/comments`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { comments, auth, error } = await res.json()
    if(auth) {
      this.setState({
        comments
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchRedemptions = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/users/${id}/redemptions`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { redemptions, auth, error } = await res.json()
    if(auth) {
      this.setState({
        redemptions
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchGrants = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/users/${id}/grants`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { grants, auth, error } = await res.json()
    if(auth) {
      this.setState({
        grants
      })
    } else {
      this.setState({
        error
      })
    }
  }
  updateUser = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/update/user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        id: this.state.id,
        verified: this.state.verified,
        subscribed: this.state.subscribed,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        username: this.state.username,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        error,
        showModal: true,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  downgradeUser = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/update/user/${this.state.id}/downgrade`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        id: this.state.id,
        when: this.state.when,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        error,
        showDowngradeWarning: false,
        showModal: true,
      })
      await this.fetchUser()
    } else {
      this.setState({
        error,
        showDowngradeWarning: false,
      })
    }
  }
  setVerified = (verification) => {
    const verified = verification === 'false' ? false : true
    this.setState({
      verified
    })
  }
  setSubscribed = (subscription) => {
    const subscribed = subscription === 'false' ? false : true
    this.setState({
      subscribed
    })
  }
  triggerDowngradeWarning = async (when) => {
    this.setState({
      when,
      showDowngradeWarning: true
    })
  }
  closeModal = () => {
    this.setState({
      showModal: false,
      showDowngradeWarning: false,
    })
  }
  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`User updated.`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        {
          this.state.showDowngradeWarning ? (
            <OptionModal
              text = {`You're about to downgrade this user. Are you sure you want to continue?`}
              yesModalText = {`Downgrade`}
              yesModal = {this.downgradeUser}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <div className = 'flex-2 column margin'>
          <form className = 'full-width column preview-input' onSubmit = {this.updateUser}>
            <select value = {this.state.verified} onChange = {(e) => this.setVerified(e.target.value)} className = {this.state.verified ? 'block fixed' : 'block fixed sample'}>
              <option value = {false}>Unverified</option>
              <option value = {true}>Verified</option>
            </select>
            <select value = {this.state.subscribed} onChange = {(e) => this.setSubscribed(e.target.value)} className = {this.state.subscribed ? 'block fixed' : 'block fixed sample'}>
              <option value = {false}>Unsubscribed</option>
              <option value = {true}>Subscribed</option>
            </select>
            <input
              placeholder = 'Email'
              onChange = {(e) => this.setState({email: e.target.value})}
              className = 'block fixed'
              value = {this.state.email}
            />
            <input
              placeholder = 'First Name'
              onChange = {(e) => this.setState({firstName: e.target.value})}
              className = 'block fixed'
              value = {this.state.firstName}
            />
            <input
              placeholder = 'Last Name'
              onChange = {(e) => this.setState({lastName: e.target.value})}
              className = 'block fixed'
              value = {this.state.lastName}
            />
            <input
              placeholder = 'Username'
              onChange = {(e) => this.setState({username: e.target.value})}
              className = 'block fixed'
              value = {this.state.username}
            />
            <p className = 'padding-left error'>{this.state.error}</p>
            <button className = 'accent block'>Save</button>
            {
              this.state.subscriptions && this.state.subscriptions.cancel_at_period_end ? (
                <div className = 'column'>
                  <button className = 'block warning' onClick = {() => this.triggerDowngradeWarning('now')}>Downgrade Now</button>
                  <span>Note: This account is set to downgrade on {moment.unix(this.state.subscriptions.cancel_at).format("MMMM DD YYYY")}</span>
                </div>
              ) : this.state.type === 'premium' ? (
                <div className = 'column'>
                  <button className = 'block warning' onClick = {() => this.triggerDowngradeWarning('now')}>Downgrade Now</button>
                  <button className = 'block warning' onClick = {() => this.triggerDowngradeWarning('later')}>Downgrade At End Of Period</button>
                </div>
              ) : null
            }
          </form>
        </div>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            {
              this.state.linkedIn && this.state.linkedIn.profilePictureURL ? (
                <img
                  src = {this.state.linkedIn.profilePictureURL}
                  alt = 'LinkedIn profile'
                  className = 'avatar'
                />
              ) : null
            }
            <p className = 'small thin'>Subscribed: {this.state.subscribed ? 'Subscribed' : 'Not subscribed'}</p>
            <p className = 'small thin'>Verified: {this.state.verified ? 'Verified' : 'Not verified'}</p>
            <p className = 'small thin'>LinkedIn: {this.state.linkedInStatus}</p>
            <p className = 'small thin'>Type: {this.state.type}</p>
            <p className = 'small thin'>Email: {this.state.email}</p>
            <p className = 'small thin'>Username: {this.state.username}</p>
            <p className = 'small thin'>First Name: {this.state.firstName}</p>
            <p className = 'small thin'>Last Name: {this.state.lastName}</p>
            <p className = 'small thin'>Next Premium Problem: {this.state.nextPremiumProblem}</p>
            <p className = 'small thin'>Next Problem: {this.state.nextProblem}</p>
            <p className = 'small thin'>Next Guide: {this.state.nextGuide}</p>
            <p className = 'small thin'>Status: {this.state.about ? this.state.about.status : null}</p>
            <p className = 'small thin'>Problem Frequency: {this.state.emailPreferences ? this.state.emailPreferences.problemFrequency : 'off'}</p>
            <p className = 'small thin'>Solution Replies: {this.state.emailPreferences && this.state.emailPreferences.solutionReplies ? 'true' : 'false'}</p>
            <p className = 'small thin'>Comment Replies: {this.state.emailPreferences && this.state.emailPreferences.commentReplies ? 'true' : 'false'}</p>
            <p className = 'small thin'>Feature Updates: {this.state.emailPreferences && this.state.emailPreferences.featureUpdates ? 'true' : 'false'}</p>
            <p className = 'small thin'>Marketing Promotions: {this.state.emailPreferences && this.state.emailPreferences.marketingPromotions ? 'true' : 'false'}</p>
            <p className = 'small thin'>Stripe ID: {this.state.stripeId}</p>
            <p className = 'small thin'>Plan: {this.state.plan}</p>
            <p className = 'small thin'>Referrer: {this.state.referrer ? this.state.referrer : null}</p>
            <p className = 'small thin'>Created At: {moment(this.state.createdAt).format('LLLL')}</p>
            <p className = 'small thin'>Last Problem Sent At: {this.state.lastProblemSentAt ? moment(this.state.lastProblemSentAt).format('LLLL') : null}</p>
            <p className = 'small thin'>Last Guide Sent At: {this.state.lastGuideSentAt ? moment(this.state.lastGuideSentAt).format('LLLL') : null}</p>
            <p className = 'small thin'>Upgraded At: {this.state.upgradedAt ? moment(this.state.upgradedAt).format('LLLL') : null}</p>
            <p className = 'small thin'>Downgraded At: {this.state.downgradedAt ? moment(this.state.downgradedAt).format('LLLL') : null}</p>
            <p className = 'small thin'>Unsubscribed At: {this.state.unsubscribedAt ? moment(this.state.unsubscribedAt).format('LLLL') : null}</p>
            <p className = 'small thin'>Revived At: {this.state.revivedAt ? moment(this.state.revivedAt).format('LLLL') : null}</p>
            <p className = 'small thin'>Snoozed Until: {this.state.snoozeUntil ? moment(this.state.snoozeUntil).format('LLLL') : null}</p>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Coupons used</p>
            <div className = 'column'>
              {
                this.state.redemptions.map(redemption => {
                  return <UserRedemptionsCard redemption = {redemption} userId = {this.state.id} />
                })
              }
            </div>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Grants received</p>
            <div className = 'column'>
              {
                this.state.grants.map(grant => {
                  return <AffiliateUserCard affiliateUser = {grant} affiliate = {grant.affiliate} user = {grant.user} affiliateId = {grant.affiliate.id} />
                })
              }
            </div>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Comments written</p>
            <div className = 'column'>
              {
                this.state.comments.map(comment => {
                  return <CommentCard comment = {comment} />
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}