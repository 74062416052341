import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import marked from 'marked'

import { STORAGE_KEY } from '@/lib/constants'

import Spinner from '@/components/Spinner'
import BasicModal from '@/components/BasicModal'
import CommentCard from '@/components/CommentCard'

export default class Problem extends PureComponent {
  state = {
    error: '',
    id: '',
    status: '',
    difficulty: '',
    title: '',
    problem: '',
    topics: [],
    companies: [],
    selectedCompanies: [],
    comments: [],
    slug: '',
    problemNumber: null,
    showModal: false,
  }
  componentDidMount = async () => {
    await this.fetchProblem()
    await this.fetchComments()
    await this.fetchCompanies()
    this.setState({
      loading: false
    })
  }
  fetchProblem = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/problems/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { problem, auth, error } = await res.json()
    if(auth) {
      this.setState({
        id: problem.id,
        status: problem.status,
        difficulty: problem.difficulty,
        title: problem.title,
        problem: problem.problem,
        topics: problem.topics,
        slug: problem.slug,
        selectedCompanies: problem.companies,
        problemNumber: problem.problemNumber,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchComments = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const id = this.props.match.params.id
    const res = await fetch(`/api/admins/problems/${id}/comments`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { comments, auth, error } = await res.json()
    if(auth) {
      this.setState({
        comments
      })
    } else {
      this.setState({
        error
      })
    }
  }
  fetchCompanies = async () => {
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/companies`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      }
    })
    const { companies, auth, error } = await res.json()
    if(auth) {
      this.setState({
        companies,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  updateProblem = async (ev) => {
    ev.preventDefault()
    const DEMO_TOKEN = await localStorage.getItem(STORAGE_KEY)
    const res = await fetch(`/api/admins/update/problem`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
        'Authorization': 'Bearer ' + DEMO_TOKEN,
        'x-access-token': DEMO_TOKEN
      },
      body: JSON.stringify({
        id: this.state.id,
        status: this.state.status,
        difficulty: this.state.difficulty,
        title: this.state.title,
        problem: this.state.problem,
        topics: this.state.topics,
        slug: this.state.slug,
        companies: this.state.selectedCompanies,
      })
    })
    const { auth, error } = await res.json()
    if(auth) {
      this.setState({
        error,
        showModal: true,
      })
    } else {
      this.setState({
        error
      })
    }
  }
  closeModal = () => {
    this.setState({
      showModal: false
    })
  }
  createMarkup = (content) => {
    if(content) {
      const html = marked(content)
      return { __html: html }
    } else {
      return { __html: ' '}
    }
  }
  setTopics = async (ev, topic) => {
    ev.preventDefault()
    let currentTopics = await this.state.topics
    this.setState({
      topics: []
    })
    if(currentTopics.indexOf(topic) >= 0) {
      await currentTopics.splice(currentTopics.indexOf(topic), 1)
    } else {
      await currentTopics.push(topic)
    }
    await this.setState({
      topics: currentTopics
    })
  }
  setCompanies = async (ev, company) => {
    ev.preventDefault()
    let currentCompanies = await this.state.selectedCompanies
    this.setState({
      selectedCompanies: []
    })
    if(currentCompanies.indexOf(company) >= 0) {
      await currentCompanies.splice(currentCompanies.indexOf(company), 1)
    } else {
      await currentCompanies.push(company)
    }
    await this.setState({
      selectedCompanies: currentCompanies
    })
  }

  render() {
    if(this.state.loading) {
      return <Spinner />
    }
    return (
      <div className = 'row'>
        {
          this.state.showModal ? (
            <BasicModal
              text = {`Problem updated.`}
              closeModal = {this.closeModal}
            />
          ) : null
        }
        <form className = 'flex-2 margin column preview-input' onSubmit = {this.updateProblem}>
          <select value = {this.state.status} onChange = {(e) => this.setState({ status: e.target.value })} className = {this.state.status === 'In progress' ? 'block fixed in-progress' : this.state.status === 'Sample' ? 'block fixed sample' : this.state.status === 'inactive' ? 'block fixed inactive' : this.state.status === 'active' ? 'block fixed' : 'block fixed'}>
            <option value = 'In progress'>In progress</option>
            <option value = 'Sample'>Sample</option>
            <option value = 'inactive'>inactive</option>
            <option value = 'active'>active</option>
          </select>
          <select value = {this.state.difficulty} onChange = {(e) => this.setState({ difficulty: e.target.value })} className = {this.state.difficulty === 'Easy' ? 'block fixed accent' : this.state.difficulty === 'Medium' ? 'block fixed in-progress' : this.state.difficulty === 'Hard' ? 'warning block fixed' : 'accent block fixed'}>
            <option value = 'Easy'>Easy</option>
            <option value = 'Medium'>Medium</option>
            <option value = 'Hard'>Hard</option>
          </select>
          <input
            placeholder = 'Title'
            onChange = {(e) => this.setState({title: e.target.value})}
            className = 'block fixed'
            value = {this.state.title}
          />
          <input
            placeholder = 'Slug'
            onChange = {(e) => this.setState({slug: e.target.value})}
            className = 'block fixed'
            value = {this.state.slug}
          />
          <div className = 'block fixed'>
            Topics
            <div className = 'row flex-wrap'>
              <button onClick = {(ev) => this.setTopics(ev, 'Design a product')} className = {this.state.topics.indexOf('Design a product') >= 0 ? 'accent block round' : 'block round'}>Design a product</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Improve a product')} className = {this.state.topics.indexOf('Improve a product') >= 0 ? 'accent block round' : 'block round'}>Improve a product</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Favorite product')} className = {this.state.topics.indexOf('Favorite product') >= 0 ? 'accent block round' : 'block round'}>Favorite product</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Fermi')} className = {this.state.topics.indexOf('Fermi') >= 0 ? 'accent block round' : 'block round'}>Fermi</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Technical')} className = {this.state.topics.indexOf('Technical') >= 0 ? 'accent block round' : 'block round'}>Technical</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Strategy')} className = {this.state.topics.indexOf('Strategy') >= 0 ? 'accent block round' : 'block round'}>Strategy</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Behavioral')} className = {this.state.topics.indexOf('Behavioral') >= 0 ? 'accent block round' : 'block round'}>Behavioral</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Measure success')} className = {this.state.topics.indexOf('Measure success') >= 0 ? 'accent block round' : 'block round'}>Measure success</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Diagnosis')} className = {this.state.topics.indexOf('Diagnosis') >= 0 ? 'accent block round' : 'block round'}>Diagnosis</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Expansion')} className = {this.state.topics.indexOf('Expansion') >= 0 ? 'accent block round' : 'block round'}>Expansion</button>
              <button onClick = {(ev) => this.setTopics(ev, 'Take-home assignment')} className = {this.state.topics.indexOf('Take-home assignment') >= 0 ? 'accent block round' : 'block round'}>Take-home assignment</button>
            </div>
          </div>
          <div className = 'block fixed'>
            Companies
            <div className = 'row flex-wrap'>
              {
                this.state.companies.map(company => {
                  return <button onClick = {(ev) => this.setCompanies(ev, company.name)} className = {this.state.selectedCompanies.indexOf(company.name) >= 0 ? 'accent block round' : 'block round'}>{company.name}</button>
                })
              }
            </div>
          </div>
          <textarea
            placeholder = 'Problem'
            onChange = {(e) => this.setState({problem: e.target.value})}
            className = 'block fixed'
            value = {this.state.problem}
            rows = '5'
          />
          <p className = 'padding-left error'>{this.state.error}</p>
          <button className = 'accent block'>Save</button>
        </form>
        <div className = 'flex-3 margin '>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Status: {this.state.status}</p>
            <p className = 'small thin'>Difficulty: {this.state.difficulty}</p>
            <p className = 'small thin'>Title: {this.state.title}</p>
            <p className = 'small thin'>Slug: {this.state.slug}</p>
            <p className = 'small thin'>Topics: {this.state.topics.map(topic => {
              return <button className = 'fixed round block accent'>{topic}</button>
            })}</p>
            <p className = 'small thin'>Companies: {this.state.selectedCompanies.map(company => {
              return <button className = 'fixed round block accent'>{company}</button>
            })}</p>
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Problem</p>
            <div className = 'preview' dangerouslySetInnerHTML = {this.createMarkup(this.state.problem)} />
          </div>
          <div className = 'card fixed block'>
            <p className = 'small thin'>Comments written</p>
            <button className = 'accent block text-align-left'>
              <NavLink to = {`/problems/${this.state.id}/comments/create-new`}>
                <p className = 'no-margin'>Add Comment</p>
              </NavLink>
            </button>
            <div className = 'column'>
              {
                this.state.comments.map(comment => {
                  return <CommentCard comment = {comment} />
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}