import React, { PureComponent } from 'react'

import './index.css'

export default class Homepage extends PureComponent {
	render() {
		return (
			<div className = 'homepage'>
				<h1>Welcome to the DPP Admin dashboard</h1>
				<p>Navigate to any of the tabs on the left for more information.</p>
			</div>
		)
	}
}