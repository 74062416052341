import React, { PureComponent } from 'react'

import Spinner from '@/components/Spinner'

export default class Logout extends PureComponent {
  componentDidMount = async () => {
    await this.props.handleLogout()
    await this.props.history.push('/')
  }
	render() {
		return <Spinner />
	}
}